import { Form, Link } from '@remix-run/react';
import { Button } from '../ui/button';
import { Balance } from '~/services/transaction.server';
export function MainLayout({
  user,
  children,
}: {
  user: UserSession;
  balance: Balance;
  children: React.ReactNode;
}) {
  return (
    <div>
      <div className="container mx-auto ">
        <div className="flex items-center justify-between p-4 mt-0 mb-4 border-b">
          <div>
            <h1 className="md:text-xl font-medium">Your Email Reactive Agent</h1>
          </div>
          <div className="flex items-center gap-4">
            {!user ? (
              <Link to="/auth/login">
                <Button>Login</Button>
              </Link>
            ) : (
              <Form action="/auth/logout" method="post">
                <Button>Logout</Button>
              </Form>
            )}
          </div>
        </div>
      </div>
      <div className="container p-4 mx-auto">{children}</div>
    </div>
  );
}
